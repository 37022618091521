
html,
body {
    height: 100%;
    margin: 0;
}

.box {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.box .row {
    border: 1px dotted grey;
}

.box .row.header {
    flex: 0 1 auto;
    /* The above is shorthand for:
    flex-grow: 0,
    flex-shrink: 1,
    flex-basis: auto
    */
}

.box .row.content {
    flex: 1 1 auto;
}

.box .row.footer {
    flex: 0 1 40px;
}

